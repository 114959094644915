.item(id='item-' + index, class=(active ? 'ui label' : ''))
  .right.floated.content
    a.back(title='Back')
      i.icon.sort.amount.down
    a.forward(title='Forward')
      i.icon.sort.amount.up
    a.display.toggle(title='Hide')
      i.icon.eye
  a.description
    i(class='icon ' + shape)
    = shape

#seeker
table#timeline.ui.inverted.structured.celled.compact.small.table
  thead
    - var n = 0;
    tr
      th( style="display: flex; justify-content: space-evenly;" ) &nbsp;
      while n < frameLimit
        th( style="text-align: center;"  data-frame-position=n )= n++
  tbody
    - n = 0;
    tr
      td( style="display: flex; justify-content: space-evenly;" )
        button.ui.compact.mini.icon.button 1x
        button#rewind.ui.compact.mini.icon.button
          i.icon.fast.backward
        button#play.ui.compact.mini.icon.button
          i.icon.play
      while n < frameLimit
        - active = n == 0 ? ' active' : ''
        td.selectable( class=active data-frame-position=n ) &nbsp;
        - n++
.active-object-context.floating.overlay
  .ui.mini.menu.labeled.icon.pointing
    a.item#btnSaveSVG
      i.save.icon
      span Save SVG
    a.item#btnDeleteActive
      i.trash.alternate.icon
      span Delete
    a.item.disabled#btnGroupActive
      i.object.group.icon
      span Group (1)
    a.item.disabled#btnMergeActive
      i.object.group.outline.icon
      span Merge
    a.item.disabled#btnFillActive
      i.tint.icon
      span Fill
    a.item.disabled
      i.pencil.alternate.icon
      span Stroke
    a#btnToggleVector.item.disabled
      i.paper.plane.outline.icon
      span Vector
    a#btnMake3D.item.disabled
      i.snowflake.outline.icon
      span 3D
